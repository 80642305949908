const moveInMoveOutImages = [
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.27 PM.jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.28 PM (1).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.28 PM (2).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.28 PM (3).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.28 PM (4).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.28 PM (5).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.28 PM (6).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.28 PM.jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.29 PM (1).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.29 PM (2).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.29 PM (3).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.29 PM (4).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.29 PM (5).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.29 PM.jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.30 PM (1).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.30 PM (2).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.30 PM (3).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.30 PM (4).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.30 PM (5).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.30 PM.jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.31 PM (1).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.31 PM (2).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.31 PM (3).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.31 PM (4).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.31 PM (5).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.31 PM (6).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.31 PM.jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.32 PM (1).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.58.32 PM.jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.22 PM (1).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.22 PM (2).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.22 PM (3).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.22 PM (4).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.22 PM.jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.23 PM (1).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.23 PM (2).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.23 PM (3).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.23 PM (4).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.23 PM (5).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.23 PM.jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.24 PM (1).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.24 PM (2).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.24 PM (3).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.24 PM (4).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.24 PM.jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.25 PM (1).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.25 PM (2).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.25 PM (3).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.25 PM (4).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.25 PM.jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.37 PM.jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.38 PM (1).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.38 PM (2).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.38 PM (3).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.38 PM (4).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.38 PM (5).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.38 PM.jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.39 PM (1).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.39 PM (2).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.39 PM (4).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.39 PM (5).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.39 PM.jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.40 PM (1).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.40 PM (2).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.40 PM (3).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.40 PM (4).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.40 PM (5).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.40 PM (6).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.40 PM.jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.41 PM (1).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 8.59.41 PM.jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 9.20.15 PM - Copy.jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 9.20.15 PM.jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 9.20.16 PM (1) - Copy.jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 9.20.16 PM (1).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 9.20.16 PM (2) - Copy.jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 9.20.16 PM (2).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 9.20.16 PM - Copy.jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 9.20.16 PM.jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 9.20.17 PM - Copy.jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 9.20.17 PM.jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 9.20.18 PM (1) - Copy.jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 9.20.18 PM (1).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 9.20.18 PM - Copy.jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 9.20.18 PM.jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 9.20.19 PM (1) - Copy.jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 9.20.19 PM (1).jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 9.20.19 PM - Copy.jpeg",
  "/images/services/moveinmoveout/WhatsApp Image 2025-03-31 at 9.20.19 PM.jpeg"
].sort(() => 0.5 - Math.random());

export default moveInMoveOutImages;
